import './App.css';
import React, {useState} from 'react';
import './rsvp.css';
import { QueryClient, QueryClientProvider, useMutation  } from "react-query";
import { 
  RequestGroupData , 
  PostGuestRecord 
 } from './rsvp_requests'
 
const queryClient = new QueryClient()

const DisplayGroupData = ({ groups }) => {
  const [formSubmitMessage, setFormSubmitMessage] = useState('');
  const [displayFormSubmitMessage, setDisplayFormSubmitMessage] = useState(false);
  const [formSubmitMessageType, setformSubmitMessageType] = useState('');

  // handles the display of a message to the user once the form is submitted
  const updateFormSubmitMessage = (message, type) => {
    setDisplayFormSubmitMessage(false);
    setformSubmitMessageType(type);
    setFormSubmitMessage(message);
    setDisplayFormSubmitMessage(true);
  }

  // handles the submit button, passing the groups object to the API and displaying a message to the user
  const handleSubmitForm = async () => {
    // console.log(groups)
    let check_response_fields = false
    try{ 
      for (const guest of groups) {
        console.log(guest)

          if (guest.rsvp_response === false) {
            console.log(`guest ${guest.guest_name} not attending, skipping other validations...`)
          } else if (guest.rsvp_response === null || guest.starter_choice === '' || guest.main_choice === '') {
              throw Error(`All required fields must be completed for all guests. Problem guest: ${guest.guest_name}`,)
          }
      }
      check_response_fields = true
      // update the rsvp message to display a waiting message should there be a delay
      updateFormSubmitMessage('Submitting your RSVP.... Please wait.', 'wait');

      const postGuestRecordResponse = await PostGuestRecord({ queryKey: ["rsvp_response_data", groups] });
      // update the rsvp message to display a success message
      if (postGuestRecordResponse[0]['status']) {
        updateFormSubmitMessage('Successfully updated RSVP responses for all guests!', 'success');
      }
      console.log('guest records posted successfully')

    } catch (e) {
      console.log(`oh no, an error! ${e}`)
      // update the rsvp message to display an error message
      if (check_response_fields === false) {
        updateFormSubmitMessage('Required fields (marked with a *) must be completed for all guests. Please try again! If the error persists, please contact the groom.', 'missingfields');
      }
      else if (check_response_fields === true) {
        updateFormSubmitMessage('There was an error submitting your response, please try again. If the error persists, please contact the groom.', 'failure');
      }
      else {
        updateFormSubmitMessage('There was an error submitting your response, please try again. If the error persists, please contact the groom.', 'failure');
      }
    }
  };


  // when a record is updated in the form, this then updates the groups object
  const OnSubmitGuestRecord = (rsvpResponseDict, id) => {
    for (const group of groups) {
      if (group.id === id){
        
        group.rsvp_response = rsvpResponseDict['rsvp_response']
        group.starter_choice = rsvpResponseDict['starter_choice']
        group.main_choice = rsvpResponseDict['main_choice']
        group.dietary_info = rsvpResponseDict['dietary_info']
        group.song_request = rsvpResponseDict['song_request']
    }
    else {
      console.log(rsvpResponseDict)
    }
  }
  // console.log(groups);

  };

  if (!groups) return <div>No results found for that code... </div>;

  // display the results of the request
  return (
    <div>

    <div className="code-results-container">
      Please update your RSVP options below. <br/>
      A * denotes a required field<br/><br/>
      <table className="code-results-table">
          {
            groups.map((group) => (
            <RSVPRowDisplay 
              key={group.id} 
              guest_id={group.id}
              name={group.guest_name} 
              rsvp_response={group.rsvp_response} 
              starter_choice={group.starter_choice} 
              main_choice={group.main_choice}
              dietary_info={group.dietary_info}
              song_request={group.song_request}   
              guest_type={group.guest_type}
              OnSubmitGuestRecord={OnSubmitGuestRecord}
              groups={groups}
            />))
            }
      
        
        
      </table>
      
      <br/>
      <div>
        {groups && (

            <button type="Submit" onClick={handleSubmitForm}>Submit RSVP</button>
          )}
      </div>

      <div>
        {displayFormSubmitMessage && formSubmitMessageType === 'wait' && (
              <div className="form-submit-message-wait">
                {formSubmitMessage}
              </div>
            )}

        {displayFormSubmitMessage && formSubmitMessageType === 'success' && (
            <div className="form-submit-message-success">
              {formSubmitMessage}
            </div>
          )}

        {displayFormSubmitMessage && formSubmitMessageType === 'missingfields' && (
            <div className="form-submit-message-missing-fields">
              {formSubmitMessage}
            </div>
          )}

        {displayFormSubmitMessage && formSubmitMessageType === 'failure' && (
            <div className="form-submit-message-failure">
              {formSubmitMessage}
            </div>
          )}


      </div>
    </div>

    </div>
  );
};

const RSVPRowDisplay = ({
  guest_id, 
  name, 
  rsvp_response, 
  starter_choice,
  main_choice,
  dietary_info,
  song_request,
  guest_type,
  OnSubmitGuestRecord
}) => {

  // state variable declarations
  const [rsvpResponse, setRSVPResponse] = useState(rsvp_response);
  const [starterChoice, setStarterChoice] = useState(starter_choice);
  const [mainChoice, setMainChoice] = useState(main_choice);
  const [dietaryInfo, setDietaryInfo] = useState(dietary_info);
  const [songRequest, setSongRequest] = useState(song_request);

  // rsvp response dict
  let guestRecordDict = {
    guest_id: guest_id,
    rsvp_response: rsvpResponse,
    starter_choice: starterChoice,
    main_choice: mainChoice,
    dietary_info: dietaryInfo,
    song_request: songRequest
  }

  // updating the guest record dict, this is passed up to the groups object through a prop
  const updateGuestRecordDict = (key, value) => {
    guestRecordDict[key] = value
    OnSubmitGuestRecord(guestRecordDict, guest_id)

  }
  
  // rsvp choice handling and submit to guest record dict
  const rsvpOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" }
  ]

  const handleRSVPResponseChange = (e) => {
    var rsvp_response_bool = (e.target.value === 'true')
    // setRSVPResponse(e.target.value)
    setRSVPResponse(rsvp_response_bool)
    updateGuestRecordDict('rsvp_response', rsvp_response_bool)
  };


  // starter choice handling and submit to guest record dict
  const starterChoiceOptions = [
    { value: "vegetarian", label: "Tomato and Vegan Cheese Arancini, Rocket Pesto, Pickled Shallots (Ve)" },
    { value: "meat", label: "Ham Hock, Apple and Real Ale Chutney with Toasted Brioche" },
    { value: "child", label: "Childrens Hummus and crudités" }
  ]


  const handleStarterChoiceChange = (e) => {
    setStarterChoice(e.target.value);
    updateGuestRecordDict('starter_choice', e.target.value)
  };



  // main choice handling and submit to guest record dict
  const mainChoiceOptions = [
    { value: "vegetarian", label: "Vegan pie (peppers, squash, chickpea, olives) and parsley mash with red pepper sauce (Ve)" },
    { value: "meat", label: "Pork belly, roasted new potatoes, parsnip and spiced apple sauce, baby apple, perfect crackling" },
    { value: "child", label: "Childrens Fish goujons, potato wedges and peas" }
  ]

  const handleMainChoiceChange = (e) => {
    setMainChoice(e.target.value);
    updateGuestRecordDict('main_choice', e.target.value)
  };



  // dietary info handling and submit to guest record dict
  // as this is free text it's handled slightly differently to the choice fields
  const [dietaryInfoTypingTimeout, setDietaryInfoTypingTimeout] = useState(null);


  // handles the dietary info getting changed, it waits for the user to stop typing before updating
  const onChangeDietaryInfo = (e) => {
    setDietaryInfo(e.target.value);
    clearTimeout(dietaryInfoTypingTimeout);
    setDietaryInfoTypingTimeout(setTimeout(() => {
      setDietaryInfo(e.target.value);
      updateGuestRecordDict('dietary_info', e.target.value)
    }, 200)); // this is the value in ms that the function will wait for before updating
  };



  // song request handling and submit to guest record dict
  // as this is free text it's handled slightly differently to the choice fields
  const [songRequestTypingTimeout, setSongRequestTypingTimeout] = useState(null);

  // handles the dietary info getting changed, it waits for the user to stop typing before updating
  const onChangeSongRequest = (e) => {
    setSongRequest(e.target.value);
    clearTimeout(songRequestTypingTimeout);
    setSongRequestTypingTimeout(setTimeout(() => {
      setSongRequest(e.target.value);
      updateGuestRecordDict('song_request', e.target.value)
    }, 200)); // this is the value in ms that the function will wait for before updating
  };
 


  // table row return for each guest object
  return (
      <tbody className='rsvp-form-guest-container'>
        {/* Guest Name - forms the title of the form section */}
        <tr>
          <td className='rsvp-form-key-title'>
            Guest Name: {name}
          </td>
        </tr>

        {/* RSVP Response */}
        <tr>
          <td className='rsvp-form-key'>RSVP Response *: </td>
          <td className='rsvp-form-value'>
            <select value={rsvpResponse ?? ""} onChange={handleRSVPResponseChange}>
              <option value="" disabled hidden>-- Please Select an Option --</option>
              {rsvpOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </td>

        </tr>

        {/* Starter Choice */}
        {guest_type === 'day' && (
          <tr>
            <td className='rsvp-form-key'>Starter Choice *: </td>
            <td className='rsvp-form-value'>
              <select value={starterChoice} onChange={handleStarterChoiceChange} required>
                <option value="" disabled hidden>-- Please Select an Option --</option>
                {starterChoiceOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </td>
          </tr>
        )}


        {/* Main Choice */}
        {guest_type === 'day' && (
          <tr>
            <td className='rsvp-form-key'>Main Choice *:</td>
            <td className='rsvp-form-value'>
              <select value={mainChoice} onChange={handleMainChoiceChange}>
                <option value="" disabled hidden>-- Please Select an Option --</option>
                {mainChoiceOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </td>
          </tr>
        )}

        {/* Dietary Info */}
        <tr>
          <td className='rsvp-form-key'>Dietary Info: </td>
          <td className='rsvp-form-value'>
            <input type="text" name="dietary_info" value={dietaryInfo} onChange={onChangeDietaryInfo}/>
          </td>

        </tr>

        {/* Song Request */}
        <tr> 
          <td className='rsvp-form-key'>Song Request: </td>

          <td className='rsvp-form-value'>
            <input type="text" name="song_request" value={songRequest} onChange={onChangeSongRequest}/>
          </td>

        </tr>
      
      </tbody>  
        
      
      
      
  );
};

// displays the login form and the group data once submitted
const RSVPLoginForm = () => {
  const [form_invite_code, update_form_invite_code] = useState("")
  const [showDisplayGroupData, setShowDisplayGroupData] = useState(false);

  const { mutate, request_loading, request_error, data } = useMutation(RequestGroupData, {
    onSuccess: () => {
      setShowDisplayGroupData(true);
    },
  });

  if (request_error) return <div>An error occurred: {request_error.message}</div>;
  if (request_loading) return <div>Fetching rsvp info...</div>;

  const setCode = event => {
    // console.log('updating invite code')
    update_form_invite_code(event.target.value)
  }

  const submitCode = event => {
    event.preventDefault();
    global.config.global_rsvp_code = form_invite_code
    mutate({ queryKey: ["groups_data", form_invite_code] });
  };

  return (
    <div>
      {!data && (
        <div>
          Enter your invite code below and click submit: <br/><br/>
          <form onSubmit={submitCode}>
          <input type="text" name="form_invite_code" value={form_invite_code} onChange={setCode}/><br/><br/>
          <button type="submit">Submit</button>
          </form>
        </div>
      )}

      {showDisplayGroupData && (
        <DisplayGroupData groups={data} /> // this handles displaying the group data
      )}
    </div>
  )
}


// the final exported object that displays the form and it's child components
const RSVPForm = () => (
  <QueryClientProvider client={queryClient}>
    <RSVPLoginForm/>
  </QueryClientProvider>
);


export default RSVPForm;