import engaged from './static/engaged.jpg'
import './HeaderImage.css';
import './fonts.css'

function HeaderImage() {
    return (
        <div className="HeaderImage">
            <div className="HeaderTitle">
                <div className="HeaderTitleText">
                    <table align="center">
                        <tbody>
                            <tr className="upper"><td>Amy</td></tr>
                            <tr className="lower"><td>and</td></tr>
                            <tr className="upper"><td>Chris</td></tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <img alt="engaged" src={engaged} />
        </div>
            );
}

export default HeaderImage;