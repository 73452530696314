import React from 'react';
import { slide as Menu } from 'react-burger-menu'
import './Menu.css';
import { NavLink } from 'react-router-dom';

// Extended from https://github.com/negomi/react-burger-menu/wiki/FAQ#i-want-to-control-the-open-state-programmatically-but-i-dont-understand-how-to-use-the-isopen-prop
class BurgerMenu extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      menuOpen: false
    }
  }

  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen})  
  }

  closeMenu () {
    this.setState({menuOpen: false})
  }

  showSettings (event) {
    event.preventDefault();
  }
  
    render () {
      // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
      return (
        <Menu
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
        >
          <NavLink className="current" to='/' onClick={() => this.closeMenu()}>Home</NavLink>
          <NavLink className="current" to='/FAQ' onClick={() => this.closeMenu()}>FAQ</NavLink>
          <NavLink className="current" to='/schedule' onClick={() => this.closeMenu()}>Schedule</NavLink>
          <NavLink className="current" to='/venue' onClick={() => this.closeMenu()}>Venue</NavLink>
          <NavLink className="current" to='/travel' onClick={() => this.closeMenu()}>Travel</NavLink>
          <NavLink className="current" to='/gifts' onClick={() => this.closeMenu()}>Gifts</NavLink>
          <NavLink className="current" to='/rsvp' onClick={() => this.closeMenu()}>RSVP</NavLink>
        </Menu>
      );
    }

  }


export default BurgerMenu;