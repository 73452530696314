import './App.css';
import './FAQ.css';
import React, { useState } from 'react';
import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";
import { faqdata } from './content/faqs.js';
import parse from 'html-react-parser';



class FAQ extends React.Component{
  render() {
    return (
      <React.StrictMode>
      <div className="pagetitle">
        Frequently asked Questions
      </div>
  
      <div className="accordion">
        {faqdata.map(({ question, answer }) => (
          <Accordion title={question} content={answer} />
        ))}
      </div>

      </React.StrictMode>
    )
  }


}

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item">
      <table class="faqtable">
        <tr>
            <td width="90%">
              <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
                <h1>{title}</h1>
              </div>
            </td>
            <td width="10%">
              <div className="accordion-title-btn" onClick={() => setIsActive(!isActive)}>
                <h1> {isActive ? <FaMinus size={25}/> : <FaPlus size={25}/>}</h1>
              </div>
            </td>
        </tr>
        <div className="accordian-content-holder">
            {isActive && <div className="accordion-content">{parse(content)}</div>}
        </div>
    
      </table>
      <br/>
    </div>
    
    
  );
};


export default FAQ