import './App.css';
import React from 'react';
import './rsvp.css';
import RSVPForm from './rsvp_form';

const RSVP = () => (
  <div>
    
    <div className="pagetitle">
      RSVP Here
    </div>

    <div className="rsvp-form">  
      <RSVPForm/>
    </div>

  </div>
);


export default RSVP