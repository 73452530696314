import './App.css';
import React from 'react';
import './travel.css';


const Travel = () => (
  <div>
    <div className="pagetitle">
      Travel Information
    </div>

    <div className="travelcontainer">
      <div className="travel-taxis">
        <div className="subtitle-green">
          Taxis
        </div>
        <div className="bodytext">
          Local taxi companies: <br/><br/>

          <div className="travel-title">
            <a href="https://001royalcabs.co.uk/buckingham/">001 Royal Cabs</a>
          </div>
          <div className="taxi-phone">
            01280 777 777
          </div>

        </div>
      </div>

      <div className="travel-hotels">
        <div className="subtitle-green">
          Hotels
        </div>
        <div className="bodytext">
          Local Hotels: <br/><br/>

          <div className="travel-title">
            <a href="https://www.travelodge.co.uk/hotels/272/Buckingham-hotel" target="_blank" rel="noreferrer">Travelodge, Buckingham</a>
          </div>

          <div className="travel-title">
            <a href="https://www.premierinn.com/gb/en/hotels/england/buckinghamshire/buckingham/buckingham.html" target="_blank" rel="noreferrer">Premier Inn, Buckingham</a>
          </div>

          <div className="travel-title">
            <a href="https://www.bestwestern.co.uk/" target="_blank" rel="noreferrer">Best Western, Buckingham</a>
          </div>

          <div className="travel-title">
            <a href="https://www.booking.com/searchresults.html?ss=MK18+5ND&ssne=MK18+5ND&ssne_untouched=MK18+5ND&efdco=1&label=gen173nr-1FCAEoggI46AdIM1gEaFCIAQGYATG4ARnIAQ_YAQHoAQH4AQKIAgGoAgO4Aoz9ybkGwAIB0gIkNjRhMDUxNWUtMWQ2My00NzZhLTk5ZWItZDkzNDVhNTBlYmNk2AIF4AIB&aid=304142&lang=en-us&sb=1&src_elem=sb&src=searchresults&latitude=52.01467&longitude=-1.07188&checkin=2025-11-18&checkout=2025-11-19&group_adults=2&no_rooms=1&group_children=0" target="_blank" rel="noreferrer">Booking.com for our dates</a>
          </div>

        </div>
      </div>

      Sorry, but we don't have any arrangements with any local hotels for the wedding.<br /><br />

    </div>
  </div>
);

export default Travel