import './App.css';
import React from 'react';
import HeaderImage from './HeaderImage';
import CountdownClock from './CountdownClock';

const Home = () => (
  <React.StrictMode>
    <HeaderImage />
    <CountdownClock />
  </React.StrictMode>
);

export default Home