import './App.css';
import React from 'react';
import './gifts.css';


const Gifts = () => (
  <div>
    <div className="pagetitle">
      Wedding Gifts
    </div>

    <div className="giftscontainer">
      <div className="bodytext">
        All we really need is our loved ones together,<br />
        As we celebrate the beginning of our wonderful forever.<br /> <br />

        We don't need bottles as we don't really drink,<br />
        And we have everything we need including the kitchen sink!<br /><br />

        But if you'd really like to gift us something on our special day,<br />
        A small donation towards our honeymoon would go a long way.<br /><br />
        ❤️<br />
      </div>
      <div className="bodytext">
        <a href="https://monzo.me/chriswray02?h-wQEC4p" target="_blank">Donate here</a>
      </div>
    </div>
  </div>
);

export default Gifts