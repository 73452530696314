import './App.css';
import React from 'react';
import './schedule.css';

const Schedule = () => (
  <div>
    <div className="pagetitle">
      Order of the Day
    </div>

    <div className="order-day-container">
      <table class="order-day-table">
        <tr>
          <td>
            13:00
          </td>
          <td>
            Day Guest Arrival
          </td>
        </tr>

        <tr>
          <td>
            13:30
          </td>
          <td>
            Ceremony begins
          </td>
        </tr>

        <tr>
          <td>
            14:00
          </td>
          <td>
            Drinks & photos
          </td>
        </tr>

        <tr>
          <td>
            15:45
          </td>
          <td>
            Wedding Breakfast & Speeches
          </td>
        </tr>

        <tr>
          <td>
            19:00
          </td>
          <td>
            Evening guest arrival
          </td>
        </tr>

        <tr>
          <td>
            19:30
          </td>
          <td>
            Music & dancing
          </td>
        </tr>

        <tr>
          <td>
            20:00
          </td>
          <td>
            More food & more dancing
          </td>
        </tr>

        <tr>
          <td>
            00:00
          </td>
          <td>
            Music ends, Go home please
          </td>
        </tr>

      </table>

    </div>
  </div>
);

export default Schedule