import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL,
  timeout: 3000,
  maxAttempts :1
})


// attach the token to each request
instance.interceptors.request.use(request => {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    request.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return request;
}, error => {
  return Promise.reject(error);
});

// intercept the response in case of an error, if it's 401 then get a new token
instance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const newAccessToken = await GetToken();
      localStorage.setItem('accessToken', newAccessToken);
      console.log('returned access token: ' + newAccessToken)
      console.log('local storage access token: ' +localStorage.getItem('accessToken'));

      instance.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
      return instance(originalRequest);
    }
    return Promise.reject(error);
  }
);

// Get a new token from the API
const GetToken = async () => {

  var formData = new FormData()
  formData.append('username', process.env.REACT_APP_API_USERNAME)
  formData.append('password', process.env.REACT_APP_API_PASSWORD)

  try {
    const response = await instance({
      method: "post",
      url: "/token",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
        //handle success
        console.log('got a new token');
        const newToken = response.data.access_token
        console.log(newToken)
        return newToken

   } catch(e) {
        //handle error
        console.log('could not get new token');
      };
};



// Get group data from invite code
const RequestGroupData = async ({queryKey}) => {
  const query_invite_code = queryKey[1]
  try{
    const response = await instance({
      method: "post",
      url: "/read_invite_code",
      data: {"code": query_invite_code},
      headers: {"Content-Type": "application/json"},
    })
      console.log('got a response for the invite code')
      return response.data
    } catch(e) {
        //handle error
        console.log('got an error response');
      };

};

// Update the guest records
const PostGuestRecord = async ({queryKey}) => {
  const guest_record = queryKey[1]

  try {

    const response = await instance({
      method: 'post',
      url: "/post_guest_response", 
      data: {guest_record: guest_record},
      headers: {"Content-Type": "application/json"},
    }
      );
      
      return response.data;

  } catch (e) {
    console.log(`oh no, an error! ${e}`)
  }
  
  
};

export { 
  RequestGroupData, 
  PostGuestRecord
};