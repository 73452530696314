import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import './fonts.css'
import BurgerMenu from './Menu';
import Home from './Home'
import FAQ from './FAQ'
import Schedule from './schedule'
import Venue from './venue'
import Travel from './travel'
import Gifts from './gifts'
import RSVP from './rsvp'

const App = () => (
  <React.StrictMode>
   {/* <div> */}
    <BurgerMenu />
    <Main />
    <Footer />
    {/* </div> */}
  </React.StrictMode>
);


const Main = () => (
  <Routes>
    <Route exact path='/' element={<Home />}></Route>
    <Route exact path='/FAQ' element={<FAQ />}></Route>
    <Route exact path='/schedule' element={<Schedule />}></Route>
    <Route exact path='/venue' element={<Venue />}></Route>
    <Route exact path='/travel' element={<Travel />}></Route>
    <Route exact path='/gifts' element={<Gifts />}></Route>
    <Route exact path='/rsvp' element={<RSVP />}></Route>
  </Routes>
  
);

const Footer = () => (
  <div className="footer">
  Content © 2024-2025 Amy and Chris Wedding Day. Website created with love and some tears.
</div>
);


export default App;