import './App.css';
import React from 'react';
import './venue.css';


const Venue = () => (
  <div>
    <div className="pagetitle">
      Venue Information
    </div>

    <div className="venuecontainer">
      <div className="subtitle-green">
        Huntsmill Farm
      </div>
      <div className="subtitle-orange">
        Address
        <div className="bodytext">
          Huntsmill Farm, Shalstone, Buckingham MK18 5ND
        </div>
        <div className="venue-map-container">
          <iframe 
            title="google_map" 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2455.5783590083684!2d-1.0739431238127204!3d52.01456067352823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876e1fd061f4b41%3A0x52c83084cbc4e2d!2sHuntsmill%20Farm!5e0!3m2!1sen!2suk!4v1731359245151!5m2!1sen!2suk" 
            allowfullscreen="" 
            width="100%"
            height="100%"
            referrerpolicy="no-referrer-when-downgrade">
          </iframe>
          </div>
      </div>

      <div className="subtitle-orange">
        Facilities
        <div className="bodytext">
          Toilets <br/>
          Parking On-site <br/>
          Card only bar <br/>
          Disabled Access <br/>
          <br/>
          For more information about the venue, visit their website <a href="https://huntsmillweddings.co.uk/" target="_blank" rel="noreferrer">here</a>
        </div>
      </div>



    </div>
  </div>
);

export default Venue