export const faqdata = [
    {
        "question": "Where is the venue?",
        "answer": "The venue is called <a target=\"_blank\" href=\"https://maps.app.goo.gl/ZnWsL7rSwsBQNvWEA\"><b>Huntsmill Farm</b></a> and is located in Shalstone, Buckinghamshire, MK18 5ND"
    },
    {
        "question": "What is the order of events for the day?",
        "answer": "Please check our <a href=\"schedule\">schedule</a> page for more information."
    },
    {
        "question": "Where can I find accomodation locally?",
        "answer": "Please check our <a href=\"travel\">travel</a> page for some options."
    },
    {
        "question": "Are children invited?",
        "answer": "Children are welcome if they have been listed on your invitation. Please contact us if you're unsure. Due to capacity limits, we can only accomodate children who have been named on your invitation"
    },
    {
        "question": "Can I bring a plus one?",
        "answer": "Plus ones are welcome if they have been listed on your invitation. Please contact us if you're unsure. Due to capacity limits, we can only accomodate plus ones who have been named on your invitation"
    },
    {
        "question": "Will food be available in the evening?",
        "answer": "Yes! We will have the talented chefs from Goujon Monkey serving up a varied menu, including vegan and vegetarian options. There will also be a selection of sweet treats and snacks available throughout. If you have specific dietary concerns please let us know directly or via your RSVP"
    },
    {
        "question": "When should I RSVP by?",
        "answer": "We need everyone's RSVP by 1st September 2025 please!"
    },
    {
        "question": "How do I RSVP?",
        "answer": "We'd prefer to receive it online, you can fill out the RSVP form using the code you will get in your invite. Please contact us if you need help with this"
    },
    {
        "question": "Is there parking available at the venue?",
        "answer": "Yes, plenty of parking available on-site. Please note that cars cannot be left overnight unless you're staying on-site."
    },
    {
        "question": "Do you have details of local taxi companies?",
        "answer": "Yes, check our <a href=\"travel\">travel</a> page here."
    },
    {
        "question": "Is there a dress code?",
        "answer": "There isn't a specific dress code, we would like everyone to look and feel their best. We would ask everyone to be photo ready and be prepared to dance!"
    },
    {
        "question": "What colours should I wear to your wedding?",
        "answer": "Anything you like with the exception of white."
    },
    {
        "question": "Can I send you pictures or videos I take at the wedding?",
        "answer": "Yes! There will be a photo upload available through our website."
    },
    {
        "question": "Is there a gift registry and if so, where are you registered for gifts?",
        "answer": "There is no gift registry! Please see our <a href=\"gifts\">gifts</a> page here with reference to gifts."
    },
    {
        "question": "Will the wedding be indoors or outdoors?",
        "answer": "The large majority of the event will be indoors. However, if weather allows, we are hoping to host a drinks reception and photos outside. Therefore, as the wedding is in November, we'd recommend you dress appropriately and bring a coat."
    },
    {
        "question": "Can I bring confetti?",
        "answer": "No, sorry! The venue only allows real flowers as confetti, we will be providing confetti for everyone to use."
    },
    {
        "question": "Will there be an open bar?",
        "answer": "No, drinks will be provided during the drinks reception and meals but following this a card-only bar will be available to purchase drinks from."
    }
]